

import { useState } from "react";
import {useAxiosInstance} from 'anthill-component-library/hooks/master'
import { useEffect } from "react";
import { SERVICES_API } from "../../../../Utilities/APIs/ServiceApi";


function ViewService({

    //common props
    targetID

}) {

    const [service, setService] = useState(null)
    const axiosInstance = useAxiosInstance()


    useEffect(() => {

        async function fetchAndSetService() {

            const { data } = await axiosInstance.get(SERVICES_API + 'getSingleAffiliateService/' + targetID)
            setService(data);
        }
        fetchAndSetService()
        
    }, [targetID, axiosInstance])

    return (

        <div className="crud_view_content">

            {service &&
                <>
                    <h1>Title</h1>
                    <p>{service?.title}</p>

            
                    <h1>Short Text</h1>
                    <p>{service?.cardText}</p>

                    <h1>Description</h1>
                    <p>{service?.description}</p>
                </>
            }
        </div>
    )
}

export default ViewService