



import { useEffect, useState } from "react"
import { useAxiosInstance } from "anthill-component-library/dist/hooks"
import { Form } from "anthill-component-library/dist/layouts"
import { LongTextInput, NumberInput, ShortTextInput } from "anthill-component-library/dist/inputs"
import { FormSubmitButton } from "anthill-component-library/dist/buttons"
import { FEEDBACK_API } from "../../../../Utilities/APIs/FeebackApi"



function UpdateFeedback({  targetID, triggerFetch, setShowUpdateForm }) {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [rating, setRating] = useState('')
    const [companyName, setCompanyName] = useState('')


    const axiosInstance = useAxiosInstance()

    useEffect(() => {
      
        async function fetchAndSetSingleFeedback() {
            const { data } = await axiosInstance.get(FEEDBACK_API + 'getSingleAffiliateFeedback/' + targetID)
            setName(data.name)
            setDescription(data.description)
            setCompanyName(data.companyName)
            setRating(data.rating)
        }
        fetchAndSetSingleFeedback()


    }, [targetID, axiosInstance])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('name', name)
        itemData.append('description', description)
        itemData.append('companyName', companyName)
        itemData.append('rating', rating)


        const response = await axiosInstance.patch(FEEDBACK_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }
    return (
        <div className='crate_form'>
        <Form onSubmit={handleSubmit} >
            <div>
                <ShortTextInput
                    label={`Name`}
                    value={name}
                    placeholder={`Enter Name`}
                    setState={setName}
                />
                <ShortTextInput
                    label={`Company Name`}
                    value={companyName}
                    placeholder={`Enter Company Name`}
                    setState={setCompanyName}
                />

                <LongTextInput
                    label={`Description`}
                    value={description}
                    placeholder={`Enter Description`}
                    setState={setDescription}
                />
                <NumberInput
                    label={`Rating`}
                    value={rating}
                    placeholder={`Enter Rating`}
                    setState={setRating}
                />


            </div>
            <FormSubmitButton text="update feedback" />

        </Form>
    </div>
    )
}

export default UpdateFeedback