import { useContext } from "react";
import { IMAGE_URL } from "../../../Utilities/APIs/APIS"
import { AuthContext } from "anthill-component-library/dist/AuthContext-e2762bed";
import { AffiliateContext } from "../../../context/AffiliateContext";

function SingleOrganization() {
    const { selectedOrganization } = useContext(AffiliateContext);

    const { employee } = useContext(AuthContext);

    const selectedOrg = employee?.affiliateOrganization?.find(org => org.affiliateOrganization._id === selectedOrganization)?.affiliateOrganization

    return ( 

        <div className="single_org">
            <img src={IMAGE_URL + selectedOrg?.image} alt={selectedOrg?.name} className="single_org_image" />
            <span className="org_name">{selectedOrg?.name}</span>
        </div>
    )
}

export default SingleOrganization