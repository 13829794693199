
import { useContext, useEffect, useState } from "react";
import {
  CRUDTable,
  CRUDTableHeader,
  CRUDth,
  ShortTextCell,
} from "anthill-component-library/crud/builder";

import {
  useAxiosInstance,
  useUseOrientation,
} from "anthill-component-library/hooks/master";
import { IconThreeDots } from "antopolis-react-icons";
import { Modal } from "anthill-component-library/elements/elements";
import { ViewButton } from "anthill-component-library/dist/buttons";


import { CONSULTENCY_API } from "../../../../Utilities/APIs/ConsultencyRequestApi";
import ViewConsultencyRequest from "./ViewConsultencyRequest";
import { AffiliateContext } from "../../../../context/AffiliateContext";

function ConsultencyRequest() {

  const [consultencyRequests, setConsultencyRequests] = useState(null);

  const [showViewSection, setShowViewSection] = useState(false);
  const [showActionButton, setShowActionButton] = useState(false);
  const { portrait } = useUseOrientation();

  const [targetID, setTargetID] = useState("")
  const [toggleFetch, setToggleFetch] = useState(false)

  const axiosInstance = useAxiosInstance();
  const { selectedOrganization } = useContext(AffiliateContext)


  useEffect(() => {
    async function fetchAndSetconsultencyRequests() {

      const { data } = await axiosInstance.get(`${CONSULTENCY_API}?affiliateOrganization=${selectedOrganization}`);
      setConsultencyRequests(data);
    }
    fetchAndSetconsultencyRequests();

  }, [toggleFetch, axiosInstance, selectedOrganization]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (

    <div className="screen_wrapper">
      <div className="screen_header">
        <h1 className="screen_heading">
          Consultancy Requests ({consultencyRequests?.length} in total)
        </h1>
      </div>

      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="Phone" />
          <CRUDth th="Email" />
          {/* <CRUDth th="Description" /> */}
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {consultencyRequests?.length > 0 && consultencyRequests?.map((consultencyRequest) => (
            <tr key={consultencyRequest._id}>
              <ShortTextCell text={consultencyRequest?.name} />
              <ShortTextCell text={consultencyRequest?.phone} />
              <ShortTextCell text={consultencyRequest?.email} />
              {/* <ShortTextCell text={consultencyRequest?.description} /> */}

              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      if (targetID === consultencyRequest._id) {
                        setTargetID("");
                      } else {
                        setShowActionButton(true);
                        setTargetID(consultencyRequest._id);
                      }
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={consultencyRequest._id}
                        setTargetID={setTargetID}
                      />


                    </div>
                  )}
                  {portrait && showActionButton && targetID === consultencyRequest._id && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={consultencyRequest._id}
                        setTargetID={setTargetID}
                      />

                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>


      </CRUDTable>



      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Consultency Request`}
          setShowModalContent={setShowViewSection}
        >
          <ViewConsultencyRequest triggerFetch={triggerFetch} targetID={targetID} />
        </Modal>
      )}


    </div>
  );
}

export default ConsultencyRequest;
