import { CLUseNavigate, CLUseParams } from 'anthill-component-library/dist/helpers';
import { useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from 'anthill-component-library/contexts/app';

function VerifyAffiliate() {
    const { token } = CLUseParams();
    const { setEmployee } = useContext(AuthContext);
    const navigate = CLUseNavigate()

    useEffect(() => {
        const verifyEmail = async () => {
            if (token) {
                try {
                    const { data } = await axios.patch(
                        `${process.env.REACT_APP_BACKEND_URL}api/antopolisAffiliateApp/public/auth/verifyAffiliate/${token}`
                    );
                    if (data?.isVerified) {
                        localStorage.setItem("employee", JSON.stringify(data));
                        navigate("/main/dashboard")
                    }
                } catch (error) {
                    console.error("Error verifying email:", error);
                }
            }
        };

        verifyEmail();
    }, [token, setEmployee, navigate]);

    return (
        <div style={{ fontSize: "2rem", color: "white", display: "grid", placeItems: "center", height: "20vh" }}>
            Successfully, You are verified
        </div>
    );
}

export default VerifyAffiliate;
