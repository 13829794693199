import { useEffect, useState } from "react"
import { useAxiosInstance } from "anthill-component-library/dist/hooks"
import { Form } from "anthill-component-library/dist/layouts"
import { LongTextInput, ShortTextInput } from "anthill-component-library/dist/inputs"
import { FormSubmitButton } from "anthill-component-library/dist/buttons"
import { PARTNER_API } from "../../../../Utilities/APIs/PartnerApi"
import { ImageInput } from "anthill-component-library/dist/ImageInput-8f19db3d"




function UpdatePartner({ targetID, triggerFetch, setShowUpdateForm }) {


    const [ceoName, setCeoName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyLogo, setCompanyLogo] = useState('')
    const [writeUps, setWriteUps] = useState('')
    const [websiteLink, setWebsiteLink] = useState('')


    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetSingleFeedback() {
            const { data } = await axiosInstance.get(PARTNER_API + 'getSingleAffiliatePartner/' + targetID)
            setCeoName(data.ceoName)
            setCompanyName(data.companyName)
            setCompanyLogo(data.companyLogo)
            setWebsiteLink(data.websiteLink)
            setWriteUps(data.writeUps)
        }
        fetchAndSetSingleFeedback()


    }, [targetID, axiosInstance])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('ceoName', ceoName)
        itemData.append('companyName', companyName)
        itemData.append('companyLogo', companyLogo)
        itemData.append('writeUps', writeUps)
        itemData.append('websiteLink', websiteLink)


        const response = await axiosInstance.patch(PARTNER_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit} hasImage>
                <div>
                    <ShortTextInput
                        label={`CEO Name`}
                        value={ceoName}
                        placeholder={`Enter CEO Name`}
                        setState={setCeoName}
                    />
                    <ShortTextInput
                        label={`Company Name`}
                        value={companyName}
                        placeholder={`Enter Company Name`}
                        setState={setCompanyName}
                    />

                    <ShortTextInput
                        label={`Website Address`}
                        value={websiteLink}
                        placeholder={`Enter Website Address`}
                        setState={setWebsiteLink}
                    />

                    <LongTextInput
                        label={`Company WriteUps`}
                        value={writeUps}
                        placeholder={`Enter Company WriteUps`}
                        setState={setWriteUps}
                    />




                    <ImageInput
                        label={`Company Logo`}
                        fieldId={"1"}
                        state={companyLogo}
                        placeholder={`Enter Company Logo`}
                        setState={setCompanyLogo}
                        allowUpdateImage
                    />


                </div>
                <FormSubmitButton text="update partner" />

            </Form>
        </div>
    )
}

export default UpdatePartner