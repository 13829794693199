
import { useState } from "react";
import {useAxiosInstance} from 'anthill-component-library/hooks/master'
import { useEffect } from "react";
import { PARTNER_API } from "../../../../Utilities/APIs/PartnerApi";
import { Image } from "anthill-component-library/dist/elements";



function ViewPartner({
    
    //common props
    targetID

}) {

    const [partner, setPartner] = useState(null)
    const axiosInstance = useAxiosInstance()


    useEffect(() => {

        async function fetchAndSetService() {

            const { data } = await axiosInstance.get(PARTNER_API + 'getSingleAffiliatePartner/' + targetID)
            setPartner(data);
        }
        fetchAndSetService()
        
    }, [targetID, axiosInstance])

    return (

        <div className="crud_view_content">

            {partner &&
                <>
                    <h1>Company Name</h1>
                    <p>{partner?.companyName}</p>

            
                    <h1>CEO Name</h1>
                    <p>{partner?.ceoName}</p>

                    <h1>Write Ups</h1>
                    <p>{partner?.writeUps}</p>

                    <h1>Website Address</h1>
                    <p>{partner?.websiteLink}</p>

                    <h1>Company Logo</h1>
                    <Image imgLink={partner?.companyLogo}/>
                </>
            }
        </div>
    )
}

export default ViewPartner