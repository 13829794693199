
import { useContext, useEffect, useState } from "react";
import {
  CRUDTable,
  CRUDTableHeader,
  CRUDth,
  CreateButton,
  ShortTextCell,
} from "anthill-component-library/crud/builder";

import {
  useAxiosInstance,
  useUseOrientation,
} from "anthill-component-library/hooks/master";
import { IconThreeDots } from "antopolis-react-icons";
import { Modal } from "anthill-component-library/elements/elements";
import { DeleteButton, EditButton, ViewButton } from "anthill-component-library/dist/buttons";


import { DeleteItem } from "anthill-component-library/dist/layouts";
import { FEEDBACK_API } from "../../../../Utilities/APIs/FeebackApi";
import ViewFeedback from "./ViewFeedback";
import UpdateFeedback from "./UpdateFeedback";
import CreateFeedback from "./CreateFeedback";
import { AffiliateContext } from "../../../../context/AffiliateContext";

function Feedbacks() {

  const [feedbacks, setFeedbacks] = useState(null);

  const [showActionButton, setShowActionButton] = useState(false);
  const { portrait } = useUseOrientation();

  const [targetID, setTargetID] = useState("")
  const [toggleFetch, setToggleFetch] = useState(false)

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const { selectedOrganization } = useContext(AffiliateContext)

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetFeedbacks() {

      const { data } = await axiosInstance.get(`${FEEDBACK_API}?affiliateOrganization=${selectedOrganization}`);
      setFeedbacks(data);
    }
    fetchAndSetFeedbacks();
  }, [toggleFetch, axiosInstance,selectedOrganization]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (

    <div className="screen_wrapper">
      <div className="screen_header">
        <h1 className="screen_heading">
          Feedbacks ({feedbacks?.length} in total)
        </h1>



        <CreateButton
          screenTopicSingular={"feedback"}
          setShowCreateForm={setShowCreateForm}
        />

      </div>

      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="Company Name" />
          <CRUDth th="Description" />
          <CRUDth th="Rating" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {feedbacks?.length > 0 && feedbacks?.map((feedback) => (
            <tr key={feedback._id}>
              <ShortTextCell text={feedback?.name} />
              <ShortTextCell text={feedback?.companyName} />
              <ShortTextCell text={feedback?.description?.length > 50 ? `${feedback?.description.slice(0, 50)}...` : feedback?.description} />
              <ShortTextCell text={feedback?.rating} />

              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      if (targetID === feedback._id) {
                        setTargetID("");
                      } else {
                        setShowActionButton(true);
                        setTargetID(feedback._id);
                      }
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={feedback._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={feedback._id}
                        setTargetID={setTargetID}
                      />
                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={feedback._id}
                        setTargetID={setTargetID}
                      />

                    </div>
                  )}
                  {portrait && showActionButton && targetID === feedback._id && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={feedback._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={feedback._id}
                        setTargetID={setTargetID}
                        setShowActionButton={setShowActionButton}

                      />
                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={feedback._id}
                        setTargetID={setTargetID}
                        setShowActionButton={setShowActionButton}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>


      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Feedback`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateFeedback
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Feedback`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateFeedback
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Feedback`}
          setShowModalContent={setShowViewSection}
        >
          <ViewFeedback triggerFetch={triggerFetch} targetID={targetID} />
        </Modal>
      )}

      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Feedback`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={FEEDBACK_API}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
            triggerFetch={triggerFetch}
            axiosInstance={axiosInstance}
          />


        </Modal>
      )}

    </div>
  );
}

export default Feedbacks;
