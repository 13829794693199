import { CLUseParams } from "anthill-component-library/dist/helpers"
import { useEffect, useState } from "react";
import {
    CRUDTable,
    CRUDTableHeader,
    CRUDth,
    CreateButton,
    ShortTextCell,
} from "anthill-component-library/crud/builder";

import {
    useAxiosInstance,
    useUseOrientation,
} from "anthill-component-library/hooks/master";
import { IconThreeDots } from "antopolis-react-icons";
import { Modal } from "anthill-component-library/elements/elements";
import { DeleteButton } from "anthill-component-library/dist/buttons";

import { DeleteItem } from "anthill-component-library/dist/layouts";


import { PARTNER_API } from "../../../../../Utilities/APIs/PartnerApi";
import CreatePartnerProjectImage from "./CreatePartnerProjectImage";
import PartnerImageCell from "../PartnerImageCell/PartnerImageCell";

function PartnerProjectImages() {

    const { partnerId } = CLUseParams()
    const [partners, setPartners] = useState(null);

    const [showActionButton, setShowActionButton] = useState(false);
    const { portrait } = useUseOrientation();

    const [targetID, setTargetID] = useState("")
    const [toggleFetch, setToggleFetch] = useState(false)

    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showDeleteSection, setShowDeleteSection] = useState(false);
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetPartners() {

            const { data } = await axiosInstance.get(`${PARTNER_API}partnerProjectImage/${partnerId}`);
            setPartners(data);
        }
        fetchAndSetPartners();
    }, [toggleFetch, axiosInstance, partnerId]);

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (

        <div className="screen_wrapper">
            <div className="screen_header">
                <h1 className="screen_heading">
                    Partners ({partners?.length} in total)
                </h1>

                <CreateButton
                    screenTopicSingular={"Project Image"}
                    setShowCreateForm={setShowCreateForm}
                />

            </div>

            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth th="Company Name" />
                    <CRUDth th="Client Name" />
                    <CRUDth th="Image" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {partners?.length > 0 && partners?.map((partner) => (
                        <tr key={partner._id}>


                            <ShortTextCell text={partner?.partner?.companyName} />
                            <ShortTextCell text={partner?.name ? partner?.name : "Not Added"} />
                            <PartnerImageCell image={partner?.image} />

                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === partner._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(partner._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">

                                            <DeleteButton
                                                setShowDeleteSection={setShowDeleteSection}
                                                targetID={partner._id}
                                                setTargetID={setTargetID}
                                            />

                                        </div>
                                    )}
                                    {portrait && showActionButton && targetID === partner._id && (
                                        <div className="action_buttons">

                                            <DeleteButton
                                                setShowDeleteSection={setShowDeleteSection}
                                                targetID={partner._id}
                                                setTargetID={setTargetID}
                                                setShowActionButton={setShowActionButton}
                                            />

                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>


            </CRUDTable>

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Project Imgae`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreatePartnerProjectImage
                        triggerFetch={triggerFetch}
                        setShowCreateForm={setShowCreateForm}
                        partnerId={partnerId}

                    />
                </Modal>
            )}


            {showDeleteSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Delete Project Image Image`}
                    setShowModalContent={setShowDeleteSection}
                >
                    <DeleteItem
                        api={PARTNER_API + "deletePartnerProjectImage/"}
                        targetID={targetID}
                        setShowDeleteItem={setShowDeleteSection}
                        triggerFetch={triggerFetch}
                        axiosInstance={axiosInstance}
                    />


                </Modal>
            )}

        </div>
    );
}

export default PartnerProjectImages;


