

import { useState } from "react";
import { useAxiosInstance } from 'anthill-component-library/hooks/master'
import { useEffect } from "react";
import { CONSULTENCY_API } from "../../../../Utilities/APIs/ConsultencyRequestApi";


function ViewConsultencyRequest({ targetID}) {

    const [consultencyRequest, setConsultencyRequest] = useState(null)
    const axiosInstance = useAxiosInstance()


    useEffect(() => {

        async function fetchAndSetconsultencyRequest() {

            const { data } = await axiosInstance.get(CONSULTENCY_API + 'getSingleAffiliateConsultencyRequest/' + targetID)
            setConsultencyRequest(data);
        }
        fetchAndSetconsultencyRequest()

    }, [targetID, axiosInstance])



    return (

        <div className="crud_view_content">

            {consultencyRequest &&
                <>

                    <h1>Name</h1>
                    <p>{consultencyRequest?.name}</p>

                    <h1>Phone</h1>
                    <p>{consultencyRequest?.phone}</p>

                    <h1>Email</h1>
                    <p>{consultencyRequest?.email}</p>

                    <h1>Message</h1>
                    <p>{consultencyRequest?.description}</p>
                </>
            }
        </div>
    )
}

export default ViewConsultencyRequest