

import { useContext, useState } from "react"

import { Form } from "anthill-component-library/dist/MediaGallery-cb8f8862"
import { ShortTextInput } from "anthill-component-library/dist/SwitchInput-069b06cf"
import { FormSubmitButton } from "anthill-component-library/dist/FormSubmitButton-196eb7e9"
import { useAxiosInstance } from "anthill-component-library/dist/hooks"

import { SERVICES_API } from "../../../../Utilities/APIs/ServiceApi"
import { LongTextInput } from "anthill-component-library/dist/TimeInput-e94415bc"
import { AffiliateContext } from "../../../../context/AffiliateContext"


function CreateService({ triggerFetch, setShowCreateForm, source }) {

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [cardText, setCardText] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const { selectedOrganization } = useContext(AffiliateContext)

    const axiosInstance = useAxiosInstance()


    async function handleSubmit(e) {
        e.preventDefault()

        setIsDisabled(true)

        const itemData = new FormData()
        itemData.append('title', title)
        itemData.append('cardText', cardText)
        itemData.append('description', description)
        itemData.append('affiliateOrganization', selectedOrganization)


        const response = await axiosInstance.post(SERVICES_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
            setIsDisabled(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit} >
                <div>
                    <ShortTextInput
                        label={`Title`}
                        value={title}
                        placeholder={`Enter title`}
                        setState={setTitle}
                    />
                    <ShortTextInput
                        label={`Short Text`}
                        value={cardText}
                        placeholder={`Enter short text`}
                        setState={setCardText}
                    />

                    <LongTextInput
                        label={`Description`}
                        value={description}
                        placeholder={`Enter Description`}
                        setState={setDescription}
                    />


                </div>
                <FormSubmitButton disabled={isDisabled} text="create Service" />

            </Form>
        </div>
    )
}

export default CreateService