

import { useState } from "react";
import { useAxiosInstance } from 'anthill-component-library/hooks/master'
import { useEffect } from "react";
import {  CONTACT_REQUEST_API } from "../../../../Utilities/APIs/ConsultencyRequestApi";


function ViewContactRequest({ targetID }) {

    const [contact, setContact] = useState(null)
    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetSingleContact() {

            const { data } = await axiosInstance.get(CONTACT_REQUEST_API + 'getSingleAffiliateContact/' + targetID)
            setContact(data);
        }
        fetchAndSetSingleContact()

    }, [targetID, axiosInstance])



    return (

        <div className="crud_view_content">

            {contact &&
                <>

                    <h1>Name</h1>
                    <p>{contact?.name}</p>

                    <h1>Email</h1>
                    <p>{contact?.email}</p>

                    <h1>Phone</h1>
                    <p>{contact?.phone}</p>

                    <h1>Message</h1>
                    <p>{contact?.message}</p>
                </>
            }
        </div>
    )
}

export default ViewContactRequest