

import { useContext, useEffect, useState } from "react";
import {
    CRUDTable,
    CRUDTableHeader,
    CRUDth,
    CreateButton,
    ShortTextCell,
} from "anthill-component-library/crud/builder";

import {
    useAxiosInstance,
    useUseOrientation,
} from "anthill-component-library/hooks/master";
import { IconThreeDots } from "antopolis-react-icons";
import { Modal } from "anthill-component-library/elements/elements";
import { CRUDButton, DeleteButton, EditButton, ViewButton } from "anthill-component-library/dist/buttons";

import { RiImage2Line } from "react-icons/ri";
import { DeleteItem } from "anthill-component-library/dist/layouts";
import { PARTNER_API } from "../../../../Utilities/APIs/PartnerApi";
import CreatePartners from "./CreatePartners";
import UpdatePartner from "./UpdatePartner";
import ViewPartner from "./ViewPartner";
import { CLUseNavigate } from "anthill-component-library/dist/helpers";
import PartnerImageCell from "./PartnerImageCell/PartnerImageCell";
import { AffiliateContext } from "../../../../context/AffiliateContext";

function Partners() {

    const { selectedOrganization } = useContext(AffiliateContext)

    const [partners, setPartners] = useState(null);

    const [showActionButton, setShowActionButton] = useState(false);
    const { portrait } = useUseOrientation();

    const [targetID, setTargetID] = useState("")
    const [toggleFetch, setToggleFetch] = useState(false)

    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showViewSection, setShowViewSection] = useState(false);
    const [showDeleteSection, setShowDeleteSection] = useState(false);
    const navigate = CLUseNavigate()
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetPartners() {

            const { data } = await axiosInstance.get(`${PARTNER_API}?affiliateOrganization=${selectedOrganization}`);
            setPartners(data);
        }
        fetchAndSetPartners();
    }, [toggleFetch, axiosInstance, selectedOrganization]);

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (

        <div className="screen_wrapper">
            <div className="screen_header">
                <h1 className="screen_heading">
                    Partners ({partners?.length} in total)
                </h1>


                <CreateButton
                    screenTopicSingular={"partner"}
                    setShowCreateForm={setShowCreateForm}
                />

            </div>

            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth th="Ceo Name" />
                    <CRUDth th="Company Name" />
                    <CRUDth th="Website" />
                    <CRUDth th=" Logo" />
                    <CRUDth th="Write Ups" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {partners?.length > 0 && partners?.map((partner) => (
                        <tr key={partner._id}>
                            <ShortTextCell text={partner?.ceoName} />
                            <ShortTextCell text={partner?.companyName} />

                            <td style={{ width: "250px", wordWrap: "break-word" }}>
                                <span style={{ maxWidth: "200px" }}> <a target="_blank" rel="noreferrer" href={partner?.websiteLink}>{partner?.websiteLink}</a></span>
                            </td>

                            <PartnerImageCell image={partner?.companyLogo} />

                            <ShortTextCell
                                text={partner?.writeUps?.length > 50 ?
                                    `${partner?.writeUps.slice(0, 50)}...` :
                                    partner?.writeUps} />

                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === partner._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(partner._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={partner._id}
                                                setTargetID={setTargetID}
                                            />
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={partner._id}
                                                setTargetID={setTargetID}
                                            />
                                            <DeleteButton
                                                setShowDeleteSection={setShowDeleteSection}
                                                targetID={partner._id}
                                                setTargetID={setTargetID}
                                            />
                                            <CRUDButton
                                                handleClick={() =>
                                                    navigate(`partner/projectImage/${partner._id}`)
                                                }
                                            >
                                                <RiImage2Line />
                                            </CRUDButton>
                                        </div>
                                    )}
                                    {portrait && showActionButton && targetID === partner._id && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={partner._id}
                                                setTargetID={setTargetID}
                                            />
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={partner._id}
                                                setTargetID={setTargetID}
                                                setShowActionButton={setShowActionButton}

                                            />
                                            <DeleteButton
                                                setShowDeleteSection={setShowDeleteSection}
                                                targetID={partner._id}
                                                setTargetID={setTargetID}
                                                setShowActionButton={setShowActionButton}
                                            />
                                            <CRUDButton
                                                handleClick={() =>
                                                    navigate(`partner/projectImage/${partner._id}`)
                                                }
                                            >
                                                <RiImage2Line />
                                            </CRUDButton>
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>


            </CRUDTable>

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Partner`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreatePartners
                        triggerFetch={triggerFetch}
                        setShowCreateForm={setShowCreateForm}
                    />
                </Modal>
            )}

            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update Partner`}
                    setShowModalContent={setShowUpdateForm}
                >
                    <UpdatePartner
                        triggerFetch={triggerFetch}
                        targetID={targetID}
                        setShowUpdateForm={setShowUpdateForm}
                    />
                </Modal>
            )}

            {showViewSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`View Partner`}
                    setShowModalContent={setShowViewSection}
                >
                    <ViewPartner triggerFetch={triggerFetch} targetID={targetID} />
                </Modal>
            )}

            {showDeleteSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Delete Partner`}
                    setShowModalContent={setShowDeleteSection}
                >
                    <DeleteItem
                        api={PARTNER_API}
                        targetID={targetID}
                        setShowDeleteItem={setShowDeleteSection}
                        triggerFetch={triggerFetch}
                        axiosInstance={axiosInstance}
                    />


                </Modal>
            )}

        </div>
    );
}

export default Partners;
