

import { useEffect, useState } from "react"
import { useAxiosInstance } from "anthill-component-library/dist/hooks"
import { Form } from "anthill-component-library/dist/layouts"
import { LongTextInput, ShortTextInput } from "anthill-component-library/dist/inputs"
import { FormSubmitButton } from "anthill-component-library/dist/buttons"
import { SERVICES_API } from "../../../../Utilities/APIs/ServiceApi"



function UpdateService({ targetID, triggerFetch, setShowUpdateForm }) {

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [cardText, setCardText] = useState('')


    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetSingleClient() {
            const { data } = await axiosInstance.get(SERVICES_API + 'getSingleAffiliateService/' + targetID)
            setTitle(data.title)
            setDescription(data.description)
            setCardText(data.cardText)
        }
        fetchAndSetSingleClient()


    }, [targetID, axiosInstance])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('title', title)
        itemData.append('cardText', cardText)
        itemData.append('description', description)

        const response = await axiosInstance.patch(SERVICES_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ShortTextInput
                        label={`Title`}
                        value={title}
                        placeholder={`Enter title`}
                        setState={setTitle}
                    />
                    <ShortTextInput
                        label={`Short Text`}
                        value={cardText}
                        placeholder={`Enter short text`}
                        setState={setCardText}
                    />
                    <LongTextInput
                        label={`Description`}
                        value={description}
                        placeholder={`Enter Description`}
                        setState={setDescription}
                    />


                </div>
                <FormSubmitButton text="Update Service" />
            </Form>
        </div>
    )
}

export default UpdateService