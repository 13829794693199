import { RiHome2Line } from "react-icons/ri";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { BsPeopleFill } from "react-icons/bs";
import { MdOutlineFeedback } from "react-icons/md";
import { GoCommentDiscussion } from "react-icons/go";
import { FaRegUser } from "react-icons/fa";


import { SidebarItem } from "anthill-component-library/layouts/master";

import "./SidebarItems.css";
import { useContext } from "react";
import { AffiliateContext } from "../../../context/AffiliateContext";
import { AuthContext } from "anthill-component-library/dist/AuthContext-e2762bed";
import SelectOrganization from "../SelectOrganization/SelectOrganization";
import SingleOrganization from "../SingleOrganization/SingleOrganization";

function SidebarItems() {


  const { employee } = useContext(AuthContext)
  return (
    <>
      {employee &&
        employee?.affiliateOrganization?.length > 1 ?
        <SelectOrganization />
        :
        <SingleOrganization />
      }
      <SidebarItem title="Dashboard" children={<RiHome2Line />} />
      <SidebarItem title="Services" children={<MdOutlineMiscellaneousServices />} />
      <SidebarItem title="Partners" children={<BsPeopleFill />} />
      <SidebarItem title="Feedbacks" children={<MdOutlineFeedback />} />
      <SidebarItem title="Contact Requests" children={<FaRegUser />} />
      <SidebarItem title="Consultancy Requests" children={<GoCommentDiscussion />} />

    </>

  );
}

export default SidebarItems;
