import { ForgotPassword, RecoverPassword, Register, Login } from "anthill-component-library/screens/authScreens"
import { axiosAuthInstance } from "../../../Utilities/HelperFunctions/axiosAuthInstance";
import { FORGOT_PASSWORD_API, LOGIN_API,  RESET_PASSWORD_API } from "../../../Utilities/APIs/AuthAPIs/AuthAPIs";
import VerifyAffiliate from "./VerifyAffiliate/VerifyAffiliate";

const AuthRoutes = [
    {
        path: "register/:token",
        element: <Register
            axiosAuthInstance={axiosAuthInstance}
            // registerApi={REGISTER_API}
        />
    },
    {
        path: "login",
        element: <Login
            axiosAuthInstance={axiosAuthInstance}
            loginApi={LOGIN_API}
        />
    },
    {
        path: "forgotPassword",
        element: <ForgotPassword
            axiosAuthInstance={axiosAuthInstance}
            forgotPasswordApi={FORGOT_PASSWORD_API}
        />
    },
    {
        path: "passwordReset/:token",
        element: <RecoverPassword
            axiosAuthInstance={axiosAuthInstance}
            resetPasswordApi={RESET_PASSWORD_API}
        />
    },
    {
        path: 'verifyAffiliate/:token',
        element : <VerifyAffiliate/>
    }


]

export default AuthRoutes