



import { useContext, useState } from "react"

import { Form } from "anthill-component-library/dist/MediaGallery-cb8f8862"
import { ShortTextInput } from "anthill-component-library/dist/SwitchInput-069b06cf"
import { FormSubmitButton } from "anthill-component-library/dist/FormSubmitButton-196eb7e9"
import { useAxiosInstance } from "anthill-component-library/dist/hooks"

import { FEEDBACK_API } from "../../../../Utilities/APIs/FeebackApi"
import { LongTextInput, NumberInput } from "anthill-component-library/dist/TimeInput-e94415bc"
import { AffiliateContext } from "../../../../context/AffiliateContext"


function CreateFeedback({ triggerFetch, setShowCreateForm }) {

    const { selectedOrganization } = useContext(AffiliateContext)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [rating, setRating] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)

    const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {
        e.preventDefault()
        setIsDisabled(true)

        const itemData = new FormData()
        itemData.append('name', name)
        itemData.append('description', description)
        itemData.append('companyName', companyName)
        itemData.append('rating', rating)
        itemData.append('affiliateOrganization', selectedOrganization)


        const response = await axiosInstance.post(FEEDBACK_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
            setIsDisabled(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit} >
                <div>
                    <ShortTextInput
                        label={`Name`}
                        value={name}
                        placeholder={`Enter Name`}
                        setState={setName}
                    />
                    <ShortTextInput
                        label={`Company Name`}
                        value={companyName}
                        placeholder={`Enter Company Name`}
                        setState={setCompanyName}
                    />

                    <LongTextInput
                        label={`Description`}
                        value={description}
                        placeholder={`Enter Description`}
                        setState={setDescription}
                    />
                    <NumberInput
                        label={`Rating`}
                        value={rating}
                        placeholder={`Enter Rating`}
                        setState={setRating}
                    />


                </div>
                <FormSubmitButton disabled={isDisabled} text="create feedback" />

            </Form>
        </div>
    )
}

export default CreateFeedback