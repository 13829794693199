
import { useContext, useEffect, useState } from "react";
import {
    CRUDTable,
    CRUDTableHeader,
    CRUDth,
    ShortTextCell,
} from "anthill-component-library/crud/builder";

import {
    useAxiosInstance,
    useUseOrientation,
} from "anthill-component-library/hooks/master";
import { IconThreeDots } from "antopolis-react-icons";
import { Modal } from "anthill-component-library/elements/elements";
import { ViewButton } from "anthill-component-library/dist/buttons";


import {  CONTACT_REQUEST_API } from "../../../../Utilities/APIs/ConsultencyRequestApi";
import ViewContactRequest from "./ViewContactRequest";
import { AffiliateContext } from "../../../../context/AffiliateContext";

function ContactRequests() {

    const [contacts, setContacts] = useState(null);

    const [showViewSection, setShowViewSection] = useState(false);
    const [showActionButton, setShowActionButton] = useState(false);
    const { portrait } = useUseOrientation();

    const [targetID, setTargetID] = useState("")
    const [toggleFetch, setToggleFetch] = useState(false)

    const axiosInstance = useAxiosInstance();
    const { selectedOrganization } = useContext(AffiliateContext)

    useEffect(() => {
        async function fetchAndSetconsultencyRequests() {

            const { data } = await axiosInstance.get(`${CONTACT_REQUEST_API}?affiliateOrganization=${selectedOrganization}`);
            setContacts(data);
        }
        fetchAndSetconsultencyRequests();
    }, [toggleFetch, axiosInstance,selectedOrganization]);


    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (

        <div className="screen_wrapper">
            <div className="screen_header">
                <h1 className="screen_heading">
                    Contact Requests ({contacts?.length} in total)
                </h1>
            </div>

            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth th="Name" />
                    <CRUDth th="Phone" />
                    <CRUDth th="Email" />
                    <CRUDth th="Message" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {contacts?.length > 0 && contacts?.map((contact) => (
                        <tr key={contact._id}>
                            <ShortTextCell text={contact?.name} />
                            <ShortTextCell text={contact?.phone} />
                            <ShortTextCell text={contact?.email} />
                            <ShortTextCell text={contact?.message?.length > 50 ? `${contact?.message?.slice(0, 50)}...` : contact?.message} />

                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            if (targetID === contact._id) {
                                                setTargetID("");
                                            } else {
                                                setShowActionButton(true);
                                                setTargetID(contact._id);
                                            }
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={contact._id}
                                                setTargetID={setTargetID}
                                            />


                                        </div>
                                    )}
                                    {portrait && showActionButton && targetID === contact._id && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={contact._id}
                                                setTargetID={setTargetID}
                                            />

                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>


            </CRUDTable>



            {showViewSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`View Contact Request`}
                    setShowModalContent={setShowViewSection}
                >
                    <ViewContactRequest triggerFetch={triggerFetch} targetID={targetID} />
                </Modal>
            )}


        </div>
    );
}

export default ContactRequests;
