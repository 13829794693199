import "./PartnerImageCell.css"

function PartnerImageCell({ image }) {
    return (
        <td className="partner_image">
            <img src={process.env.REACT_APP_SPACES_URL + image} alt="Partner Logo" />
        </td>
    )
}

export default PartnerImageCell