






import { useState } from "react"

import { Form } from "anthill-component-library/dist/MediaGallery-cb8f8862"

import { FormSubmitButton } from "anthill-component-library/dist/FormSubmitButton-196eb7e9"
import { useAxiosInstance } from "anthill-component-library/dist/hooks"

import { ImageInput } from "anthill-component-library/dist/ImageInput-8f19db3d"
import { PARTNER_API } from "../../../../../Utilities/APIs/PartnerApi"
import { ShortTextInput } from "anthill-component-library/dist/SwitchInput-069b06cf"


function CreatePartnerProjectImage({ triggerFetch, setShowCreateForm, partnerId }) {

    const [image, setImage] = useState('')
    const [name, setName] = useState("")
    const [isDisabled, setIsDisabled] = useState(false)

    const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {
        e.preventDefault()
        setIsDisabled(true)

        const itemData = new FormData()
        itemData.append('image', image)
        itemData.append('name', name)

        const response = await axiosInstance.post(PARTNER_API + "partnerProjectImage/" + partnerId, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
            setIsDisabled(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit} hasImage>
                <div>

                    <ShortTextInput
                        label={`Name`}
                        value={name}
                        placeholder={`Enter Name`}
                        setState={setName}
                    />
                    <ImageInput
                        fieldId={"1"}
                        banner={"Project Image"}
                        label={`Company Logo`}
                        state={image}
                        placeholder={`Enter Image`}
                        setState={setImage}
                        allowCreateImage
                    />


                </div>
                <FormSubmitButton disabled={isDisabled} text="create project image" />
            </Form>
        </div>
    )
}

export default CreatePartnerProjectImage