import { useContext, useEffect, useState } from "react";
import { CRUDBoilerPlate } from 'anthill-component-library/crud/boilerplate'
import {
  CRUDTable,
  CRUDTableHeader,
  CRUDth,
  CreateButton,
  ShortTextCell,
} from "anthill-component-library/crud/builder";

import {
  useAxiosInstance,
  useUseOrientation,
} from "anthill-component-library/hooks/master";
import { IconThreeDots } from "antopolis-react-icons";
import { Modal } from "anthill-component-library/elements/elements";
import { DeleteButton, EditButton, ViewButton } from "anthill-component-library/dist/buttons";

import CreateService from "./CreateService";
import UpdateService from "./UpdateService";
import ViewService from "./ViewService";
import { SERVICES_API } from "../../../../Utilities/APIs/ServiceApi";
import "./Services.css"
import { DeleteItem } from "anthill-component-library/dist/layouts";
import { AffiliateContext } from "../../../../context/AffiliateContext";

function Services() {

  const [services, setServices] = useState(null);

  const [showActionButton, setShowActionButton] = useState(false);
  const { portrait } = useUseOrientation();

  const [targetID, setTargetID] = useState("")
  const [toggleFetch, setToggleFetch] = useState(false)

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const { selectedOrganization } = useContext(AffiliateContext)

  console.log("🚀 ~ Services ~ selectedOrganization:", selectedOrganization)


  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetServices() {

      const { data } = await axiosInstance.get(`${SERVICES_API}?affiliateOrganization=${selectedOrganization}`);
      setServices(data);
    }
    fetchAndSetServices();
  }, [toggleFetch, axiosInstance, selectedOrganization]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (

    <div className="screen_wrapper">
      <div className="screen_header">
        <h1 className="screen_heading">
          Services ({services?.length} in total)
        </h1>



        <CreateButton
          screenTopicSingular={"service"}
          setShowCreateForm={setShowCreateForm}
        />

      </div>

      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Title" />
          <CRUDth th="Short Text" />
          <CRUDth th="Description" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {services?.length > 0 && services?.map((service) => (
            <tr key={service._id}>
              <ShortTextCell text={service?.title} />
              <ShortTextCell text={service?.cardText?.length > 50 ? `${service?.cardText.slice(0, 50)}...` : service?.cardText} />
              <ShortTextCell text={service?.description?.length > 50 ? `${service?.description.slice(0, 50)}...` : service?.description} />

              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      if (targetID === service._id) {
                        setTargetID("");
                      } else {
                        setShowActionButton(true);
                        setTargetID(service._id);
                      }
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={service._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={service._id}
                        setTargetID={setTargetID}
                      />
                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={service._id}
                        setTargetID={setTargetID}
                      />

                    </div>
                  )}
                  {portrait && showActionButton && targetID === service._id && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={service._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={service._id}
                        setTargetID={setTargetID}
                        setShowActionButton={setShowActionButton}

                      />
                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={service._id}
                        setTargetID={setTargetID}
                        setShowActionButton={setShowActionButton}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>


      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create service`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateService
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update service`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateService
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View service`}
          setShowModalContent={setShowViewSection}
        >
          <ViewService triggerFetch={triggerFetch} targetID={targetID} />
        </Modal>
      )}

      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete service`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={SERVICES_API}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
            triggerFetch={triggerFetch}
            axiosInstance={axiosInstance}
          />


        </Modal>
      )}

    </div>
  );
}

export default Services;
