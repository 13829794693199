import { useContext, useState, useEffect, useRef } from "react";
import { AffiliateContext } from "../../../context/AffiliateContext";
import { AuthContext } from "anthill-component-library/dist/AuthContext-e2762bed";
import { IMAGE_URL } from "../../../Utilities/APIs/APIS";
import InititalSelectOrganization from "./InititalSelectOrganization";

function SelectOrganization() {
    const { setSelectedOrganization } = useContext(AffiliateContext);
    const { employee } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    function handleSelect (org) {
        setSelectedOrganization(org._id);
        localStorage.setItem("selectedOrganization", org._id);
        setIsOpen(false);
    };

    return (
        <div className="custom-dropdown" ref={dropdownRef}>
            <InititalSelectOrganization isOpen={isOpen} setIsOpen={setIsOpen} />
            {isOpen && (
                <div className="dropdown-options">
                    {employee?.affiliateOrganization?.map((org) => (
                        <div
                            key={org?.affiliateOrganization?._id}
                            className="dropdown-option"
                            onClick={() => handleSelect(org?.affiliateOrganization)}
                        >
                            <img
                                src={IMAGE_URL + org?.affiliateOrganization?.image}
                                alt={org?.affiliateOrganization?.name} className="option-image"
                            />
                            {org?.affiliateOrganization?.name}

                        </div>
                    ))}

                </div>
            )}

        </div>
    );
}

export default SelectOrganization;
