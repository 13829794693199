import { createContext, useEffect, useState } from "react";

const AffiliateContext = createContext({})

function AffiliateContextProvider({ children }) {
    const [selectedOrganization, setSelectedOrganization] = useState(null);

    useEffect(() => {
        const savedOrganization = localStorage.getItem("selectedOrganization");

        if (savedOrganization) {
            setSelectedOrganization(savedOrganization);
        } else {
            const employeeData = JSON.parse(localStorage.getItem("employee"));
            if (employeeData && employeeData?.affiliateOrganization?.length > 0) {
                const initialOrganization = employeeData.affiliateOrganization[0]._id;
                setSelectedOrganization(initialOrganization);
                localStorage.setItem("selectedOrganization", initialOrganization);
            }
        }
    }, []);

    
    return (
        <AffiliateContext.Provider
            value={{
                selectedOrganization,
                setSelectedOrganization
            }}
        >
            {children}
        </AffiliateContext.Provider>
    )
}

export {
    AffiliateContextProvider,
    AffiliateContext
}
