import { useContext } from "react";
import { AffiliateContext } from "../../../context/AffiliateContext";
import { AuthContext } from "anthill-component-library/dist/AuthContext-e2762bed";
import { IMAGE_URL } from "../../../Utilities/APIs/APIS";

function InititalSelectOrganization({ isOpen, setIsOpen }) {
    const { selectedOrganization } = useContext(AffiliateContext);

    const { employee } = useContext(AuthContext);

    const selectedOrg = employee?.affiliateOrganization?.find(org => org.affiliateOrganization._id === selectedOrganization)?.affiliateOrganization

    return (
        <div className="selected-item" onClick={() => setIsOpen(!isOpen)}>
            {selectedOrg ? (
                <>
                    <img src={IMAGE_URL + selectedOrg?.image} alt={selectedOrg?.name} className="selected-image" />
                    {selectedOrg.name}
                </>
            ) : (
                "Select an organization"
            )}
        </div>
    )
}

export default InititalSelectOrganization