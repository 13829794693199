import { useContext, useState } from "react"

import { Form } from "anthill-component-library/dist/MediaGallery-cb8f8862"
import { ShortTextInput } from "anthill-component-library/dist/SwitchInput-069b06cf"
import { FormSubmitButton } from "anthill-component-library/dist/FormSubmitButton-196eb7e9"
import { useAxiosInstance } from "anthill-component-library/dist/hooks"

import { LongTextInput } from "anthill-component-library/dist/TimeInput-e94415bc"
import { PARTNER_API } from "../../../../Utilities/APIs/PartnerApi"
import { ImageInput } from "anthill-component-library/dist/ImageInput-8f19db3d"
import { AffiliateContext } from "../../../../context/AffiliateContext"


function CreatePartners({ triggerFetch, setShowCreateForm }) {

    const { selectedOrganization } = useContext(AffiliateContext)

    const [ceoName, setCeoName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyLogo, setCompanyLogo] = useState('')
    const [writeUps, setWriteUps] = useState('')
    const [websiteLink, setWebsiteLink] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)


    const axiosInstance = useAxiosInstance()

    async function handleSubmit(e) {
        e.preventDefault()
        setIsDisabled(true)

        const itemData = new FormData()
        itemData.append('ceoName', ceoName)
        itemData.append('companyName', companyName)
        itemData.append('companyLogo', companyLogo)
        itemData.append('writeUps', writeUps)
        itemData.append('websiteLink', websiteLink)
        itemData.append('affiliateOrganization', selectedOrganization)



        const response = await axiosInstance.post(PARTNER_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
            setIsDisabled(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit} hasImage>
                <div>
                    <ShortTextInput
                        label={`CEO Name`}
                        value={ceoName}
                        placeholder={`Enter CEO Name`}
                        setState={setCeoName}
                    />
                    <ShortTextInput
                        label={`Company Name`}
                        value={companyName}
                        placeholder={`Enter Company Name`}
                        setState={setCompanyName}
                    />

                    <ShortTextInput
                        label={`Website Address`}
                        value={websiteLink}
                        placeholder={`Enter Website Address`}
                        setState={setWebsiteLink}
                    />

                    <LongTextInput
                        label={`Company WriteUps`}
                        value={writeUps}
                        placeholder={`Enter Company WriteUps`}
                        setState={setWriteUps}
                    />

                    <ImageInput
                        fieldId={"1"}
                        label={`Company Logo`}
                        state={companyLogo}
                        placeholder={`Enter Company Logo`}
                        setState={setCompanyLogo}
                        allowCreateImage
                    />


                </div>
                <FormSubmitButton disabled={isDisabled} text="create partner" />

            </Form>
        </div>
    )
}

export default CreatePartners