import ConsultencyRequest from "./ConsultencyRequest/ConsultencyRequest"
import ContactRequests from "./ContactRequest/ContactRequests"
import Dashboard from "./Dashboard/Dashboard"
import Feedbacks from "./Feedbacks/Feedbacks"
import PartnerProjectImages from "./Partners/PartnerProjectImages/PartnerProjectImages"
import Partners from "./Partners/Partners"
import Services from "./Services/Services"

const MainRoutes = [
    {
        path: 'dashboard',
        element: <Dashboard />
    },
    {
        path: 'services',
        element: <Services />
    },
    {
        path: 'partners',
        element: <Partners />
    },
    {
        path: 'partners/partner/projectImage/:partnerId',
        element: <PartnerProjectImages />
    },
    {
        path: 'feedbacks',
        element: <Feedbacks />
    },
    {
        path: 'contactRequests',
        element: <ContactRequests />
    },
    {
        path: 'consultancyRequests',
        element: <ConsultencyRequest />
    }
    
]

export default MainRoutes