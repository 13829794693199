import { CLCreateBrowserRouter } from 'anthill-component-library/helpers/master';
import AuthRoutes from './Screens/AuthScreens/AuthRoutes';
import MainRoutes from './Screens/MainScreens/MainRoutes';
import { MainScreens } from 'anthill-component-library/screens/mainScreens';
import { AuthScreens } from 'anthill-component-library/screens/authScreens';
import {App} from 'anthill-component-library/layouts/master'

import SidebarItems from './Partials/SidebarItems/SidebarItems';

const routes = CLCreateBrowserRouter([
  {
    path: '/',
    element: <App NavItems={<SidebarItems />} />,
    children: [
      {
        path: '/main',
        element: <MainScreens />,
        children: MainRoutes
      },
      {
        path: '/auth',
        element: <AuthScreens />,
        children: AuthRoutes
      }
    ]
  }

])

export default routes