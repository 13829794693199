


import { useState } from "react";
import {useAxiosInstance} from 'anthill-component-library/hooks/master'
import { useEffect } from "react";
import { FEEDBACK_API } from "../../../../Utilities/APIs/FeebackApi";


function ViewFeedback({

    //common props
    targetID

}) {

    const [feedback, setFeedback] = useState(null)
    const axiosInstance = useAxiosInstance()


    useEffect(() => {

        async function fetchAndSetService() {

            const { data } = await axiosInstance.get(FEEDBACK_API + 'getSingleAffiliateFeedback/' + targetID)
            setFeedback(data);
        }
        fetchAndSetService()
        
    }, [targetID, axiosInstance])

    return (

        <div className="crud_view_content">

            {feedback &&
                <>
                    <h1>Name</h1>
                    <p>{feedback?.name}</p>

            
                    <h1>Company Name</h1>
                    <p>{feedback?.companyName}</p>

                    <h1>Description</h1>
                    <p>{feedback?.description}</p>

                    <h1>Rating</h1>
                    <p>{feedback?.rating}</p>
                </>
            }
        </div>
    )
}

export default ViewFeedback